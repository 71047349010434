@import '../../variables.scss';

.app-header {
  background-color: $bg-pastel;
}

.app-header__inner {
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: $spacing-s $spacing-s;
}

.app-header__home-link {
  text-decoration: none;
  color: $html-blue;
  margin-right: auto;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0;

  h1 {
    line-height: 0;
  }

  &:hover, &:focus {
    background: transparent;
    text-decoration: none;
  }
}

.app-header__title {
  font-size: $font-size-m;
}
