@import '../../variables.scss';
$offset: 3px;

.button {
  position: relative;
  padding: $spacing-xs $spacing-m;

  font-size: $font-size-s;
  color: $white;

  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }
}

.button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  border-radius: 4px;
  background-color: $html-blue;
  pointer-events: none;

  transition: transform .15s linear, background-color .15s linear;
}

.button[disabled]:before {
  background-color: lighten($html-blue, 20%)
}

.button:not(:disabled):focus:before,
.button:not(:disabled):hover:before {
  transform: scale(1.05);
}

.button:not(:disabled):focus:before {
  box-shadow: 0 0 0 3px rgba($html-blue, .3);
}

.button[variant="light"] {
  color: $black;

  &:before {
    background-color: $fog;
  }

  &:hover:before,
  &:focus:before {
    background-color: $very-dim;
  }
}

.button:disabled {
  opacity: 0.5;
}

.button__inner {
  position: relative;
  z-index: 2;
}
