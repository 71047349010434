// SPACING
$spacing-xxs: .25rem;
$spacing-xs: .5rem;
$spacing-s: 1rem;
$spacing-m: 1.5rem;
$spacing-l: 2rem;
$spacing-xl: 2.5rem;
$spacing-xxl: 3rem;
$spacing-uxl: 3.5rem;
$spacing-uxxl: 4rem;

// COLORS
$html-blue: blue;
$brand-yellow: #ffe400;
$bg-pastel: #fffeca;
$black: #222222;
$off-black: #555555;
$very-dim: #dddddd;
$fog: #f6f6f6;
$white: #ffffff;
$warn: #e74c3c;
$caution: #e67e22;
$success: #27AE60;

// TYPOGRAPHY
$font-family-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; 
$font-body-color: $black;

$font-size-xxs: .8rem;
$font-size-xs: 1rem;
$font-size-s: 1.125rem;
$font-size-default: 1.375rem;
$font-size-m: 1.75rem;
$font-size-l: 2rem;
$font-size-xl: 2.25rem;
$font-size-xxl: 2.75rem;
$font-size-uxl: 3rem;
$font-size-uxxl: 3.125rem;
$font-size-uul: 3.5rem;

$font-weight-normal: 400;
$font-weight-bold: 700;

// SIZING
$max-width: 1000px;

$min-width-m: 640px;
$min-width-l: 768px;
$min-width-xl: 960px;
$min-width-xxl: 1100px;
