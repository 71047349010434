/* Typography */

body {
  font-family: $font-family-default;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $font-body-color;
  font-size: 100%;
  line-height: 1.4;
}

.h1 {
  font-size: $font-size-uxl;
  font-weight: $font-weight-bold;
  margin: $spacing-s 0;

  @media (min-width: $min-width-l) {
    font-size: $font-size-uul;
  }
}

.h2 {
  font-size: $font-size-xxl;
  font-weight: $font-weight-bold;
  line-height: 1;

  @media (min-width: $min-width-l) {
    font-size: $font-size-uxxl;
  }
}

.h3 {
  font-size: $font-size-l;
  font-weight: $font-weight-bold;

  @media (min-width: $min-width-l) {
    font-size: $font-size-xl;
  }
}

.h4 {
  font-size: $font-size-default;
  font-weight: $font-weight-bold;

  @media (min-width: $min-width-l) {
    font-size: $font-size-m;
  }
}

.p {
  font-size: $font-size-xs;

  @media (min-width: $min-width-l) {
    font-size: $font-size-default;
  }
}

a {
  padding-bottom: .15rem;
  color: $html-blue;
  text-decoration: none;

  &:hover,
  &:focus {
    background: transparent linear-gradient(0deg, $html-blue 2px, transparent 0);
  }

  &:visited {
    color: $html-blue;
  }
}

a.link-black {
  color: $black;

  &:hover,
  &:focus {
    background: transparent linear-gradient(0deg, $black 2px, transparent 0);
  }

  &:visited {
    color: $black;
  }
}

.blue {
  color: $html-blue;
}

.red {
  color: $warn;
}

.green {
  color: $success;
}

.bold {
  font-weight: 700;
}

