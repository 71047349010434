@import '../../variables.scss';

$navigation-trigger-size: 2.5rem;

.primary-navigation--trigger {
  padding: 0.25rem;
  width: $navigation-trigger-size;
  height: $navigation-trigger-size;
  background-color: $html-blue;
  border: none;
  cursor: pointer;
}

.primary-navigation-link--active {
  background: transparent linear-gradient(0deg, $html-blue 2px, transparent 0);
}

.primary-navigation {
  flex: 0 0 100%;
  padding-top: $spacing-xs;

  @media (min-width: $min-width-m) {
    flex: 0 1 auto;
    margin-left: auto;
    padding: 0;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;

    @media (min-width: $min-width-m) {
      flex-direction: row;
      align-items: center;
    }
  }
}

.primary-navigation-link {
  display: inline-block;
  padding: $spacing-s 0;
  width: 100%;

  @media (min-width: $min-width-m) {
    margin-left: 2rem;
    padding: 0 0 .15rem;
    width: auto;
  }
}
