@import './variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-y: scroll; 
}

body {
  background-color: $white;
}

html,
body,
#root {
  height: 100%;
}

.App {
  padding: $spacing-s;
}

.blue-line {
  padding: $spacing-xxs $spacing-s;
  border-left: 2px solid $html-blue;
}

.a11y-sr-only {
  overflow: hidden;
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0 0 0 0);
}

.flat-list {
  list-style: none;
}

@import './typography';